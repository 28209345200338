import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Loader, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { ScrollPanel } from 'components/containers';

import { CardMilestone } from '..';
import { MilestoneReduced } from '../types';

import styles from './MilestonesList.module.scss';

const MilestonesList = () => {
  const location = useLocation<{ reload?: any }>();

  const { id: projectId } = useParams<{ id: string }>();

  const { data: milestones, reload } = useFetch<MilestoneReduced[]>(
    `/projeto/${projectId}/macroentregas`
  );

  const { data: projetoData } = useFetch<{ duracao: number; finalizacao: string, anoInicioExecucao: number, mesInicioExecucao: number }>(
    `/projetos/${projectId}/projeto`
  );

  const modifiedDate = new Date(projetoData?.finalizacao as string);
  modifiedDate.setMonth(modifiedDate.getMonth() - (projetoData?.duracao || 0));
  const projectStartDate = modifiedDate.getMonth() + 1;

  const history = useHistory();

  const { tx } = useTranslationX('milestonesCards', 'project');

  useEffect(() => {
    if (location.state?.reload) {
      reload();
    }
  }, [reload, location]);

  if (projetoData === undefined) {
    return <></>
  }

  return (
    <div className={styles.rolesList}>
      <h1 className={styles.header}>{tx('title')}</h1>
      <WrapperCard padding="15px 0px 20px 20px" width="90%" maxWidth="90%">
        <ScrollPanel vBar={{ overlay: true }} style={{ content: { maxHeight: '400px' } }}>
          {milestones !== undefined && milestones.length > 0 ? (
            <div className={styles.grid}>
              {milestones
                .sort((a, b) => a.ordem - b.ordem)
                .map(card => (
                  <CardMilestone
                    key={card.id}
                    data={card}
                    showTermGeneration
                    inicioRelativo={new Date(projetoData.anoInicioExecucao, projetoData?.mesInicioExecucao - 1, 2).toISOString()}
                    onItemSelected={() => {
                      history.push(`${location.pathname}/${card.id}`);
                    }}
                    startDate={projectStartDate}
                  />
                ))}
            </div>
          ) : (
            <Loader type="spin" />
          )}
        </ScrollPanel>
      </WrapperCard>
    </div>
  );
};

export default MilestonesList;
