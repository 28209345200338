import React from 'react';

import { ModalidadeParte } from 'pages/proposal/types';
import { Row } from 'components/containers';
import { FieldSet, TextField } from 'components/form';
import { useTranslationX } from 'i18n';

import styles from './BankDataForm.module.scss';

type Props = {
  partes: ModalidadeParte[];
};

const BankDataForm: React.FC<Props> = ({ partes }) => {
  const { tx } = useTranslationX('create', 'project');

  return (
    <div className={styles.container}>
      <h1>{tx('bankData')}</h1>
      {partes.map(parte => {
        return (
          <FieldSet name={parte.id.toString()} key={parte?.id}>
            <div key={parte.id} className={styles.bankContainer}>
              <h2>{parte.nome}</h2>
              <Row width={[0.25, 0.35, 0.35, 0.35]}>
                <TextField
                  metadata={{
                    rules: {
                      required: true,
                      uniqueInSection: {
                        section: 'bankData',
                        field: 'gpf',
                      },
                    },
                  }}
                  label={tx('gpf')}
                  name="gpf"
                />
                <TextField
                  metadata={{ rules: { required: true } }}
                  label={tx('bank')}
                  name="banco"
                />
                <TextField
                  metadata={{ rules: { required: true, mask: { pattern: '0000' } } }}
                  label={tx('agency')}
                  name="agencia"
                />
                <div className={styles.helper}>
                  <TextField
                    name="conta"
                    label={tx('account')}
                    metadata={{ rules: { required: true, mask: { pattern: 'account' } } }}
                  />
                  <span>{tx('helper')}</span>
                </div>
              </Row>
            </div>
          </FieldSet>
        );
      })}
    </div>
  );
};

export default BankDataForm;
