import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import HeaderCell from 'components/containers/TableList/HeaderCell';
import { DispatchFilter, StateFilter } from 'components/containers/TableList/types';

import { bindTableFixedBehavior, classes } from 'utils/components';
import { FaArrowLeft, FaArrowRight, FaRegIdCard } from 'react-icons/fa';
import PaymentNode from './PaymentNode/PaymentNode';
import { useWindowSize } from 'components/hooks';

import { Payment, PaymentContract, PaymentStatus } from '../types';
import {
  DonutChart,
  IconButton,
  Loader,
  NamedUserBadge,
  NotFoundMessage,
} from 'components/elements';
import { getMasked } from 'utils/inputMask';
import { useTranslationX } from 'i18n';
import { capitalize } from 'utils/stringUtils';
import { usePaymentContext } from '../PaymentContext';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';

import { ScrollPanel } from 'components/containers';
import { DEFAULT_TIMEZONE } from 'consts';

import styles from './PaymentTable.module.scss';

type Props = {
  dispatchFilter?: DispatchFilter;
  stateFilter?: StateFilter;
  fonte: string;
};

const PaymentTable: React.FC<Props> = ({ dispatchFilter, stateFilter, fonte }) => {
  const {
    contracts: data,
    currentMonthPayments,
    defineCurrentMonthPayments,
  } = usePaymentContext();
  const { state } = useProjectEditor();
  const [offset, setOffset] = useState(0);
  const [headerH, setHeaderH] = useState(0);
  const { width } = useWindowSize();

  const { tx } = useTranslationX('paymentPanel', 'project');

  const getSlicedMonthsValues = () => {
    if (width >= 1920) return Math.min(14, data.duracao);
    if (width >= 1440) return Math.min(12, data.duracao);
    if (width >= 1350) return Math.min(10, data.duracao);

    return Math.min(8, data.duracao);
  };

  const totalMonths = Math.min(getSlicedMonthsValues(), data.duracao);
  const paymentContracts = _.filter(data.contratos, { fonte });
  const payments = _.flatMap(paymentContracts, contract =>
    contract.pagamentos.map(payment => ({ ...payment, valor: contract.valor }))
  );

  useEffect(() => {
    if (totalMonths + offset > data.duracao) {
      const diff = totalMonths + offset - data.duracao;
      setOffset(Math.max(0, offset - diff));
    }
  }, [data.duracao, offset, totalMonths, width]);

  useEffect(() => {
    if (!currentMonthPayments.length) {
      const currentPayments = payments
        .map(payment => {
          const month = payment.mes;
          const relativeMonth = month + offset;
          const paymentDate = moment(data.inicioRelativo)
            .endOf('day')
            .add(relativeMonth - 1, 'months')
            .utcOffset(DEFAULT_TIMEZONE)
            .toDate();

          const today = new Date();

          const currentMonth = new Date().getMonth() + 1;
          const isElegible = payment.estado !== 'EXE' && payment.estado !== 'REQ';
          const isValidDate = today.getDate() >= 15;

          if (currentMonth === paymentDate.getMonth() + 1 && isElegible && isValidDate) {
            return payment;
          }

          return null;
        })
        .filter(Boolean) as Payment[];

      defineCurrentMonthPayments(currentPayments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMonthPayments.length,
    data.inicioRelativo,
    offset,
    defineCurrentMonthPayments,
  ]);

  if (Object.keys(data).length === 0 || !data) {
    return <Loader type="spin" />;
  }

  const onAddOffset = () => {
    setOffset(offset => {
      const remainingMonths = data.duracao - (offset + totalMonths);
      return remainingMonths > 0
        ? offset + 1 // Move forward if there are remaining months
        : offset; // Do nothing if no remaining months
    });
  };

  const onRemoveOffset = () => {
    setOffset(offset => {
      return offset > 0
        ? offset - 1 // Move backward if we're not at the start
        : 0; // Keep at zero if we can't go further back
    });
  };
  const renderHeaderMonthNode = (relativeMonth: number) => {
    return (
      <div>
        <span>
          {tx('month', {
            month: relativeMonth,
          })}
        </span>
        <span>{`${state.meses ? state.meses[relativeMonth - 1] : '...'}`}</span>
      </div>
    );
  };

  if (paymentContracts.length === 0) {
    return (
      <NotFoundMessage
        title={tx('notFound.title')}
        description={tx('notFound.description')}
        icon={FaRegIdCard}
      />
    );
  }

  return (
    <div className={classes(styles.paymentTable)}>
      <ScrollPanel
        vBar={{ start: headerH, overlay: true }}
        style={{
          root: {
            maxHeight: '55vh',
          },
        }}
      >
        <table>
          <thead ref={ref => (ref ? setHeaderH(ref.offsetHeight) : null)}>
            <tr>
              <HeaderCell
                level={0}
                dispatchFilter={dispatchFilter}
                stateFilter={stateFilter}
                config={{
                  title: tx('table.name'),
                  field: 'name',
                  colspan: 1,
                  rowspan: 1,
                  align: 'left',
                  sort: false,
                  search: true,
                }}
              />
              <td ref={bindTableFixedBehavior}>
                <IconButton
                  rate={0.4}
                  icon={FaArrowLeft}
                  shape="circle"
                  onClick={onRemoveOffset}
                  type={offset === 0 ? 'disabled' : 'dark'}
                />
              </td>
              {Array.from(new Array(totalMonths), (_el, index) => {
                return (
                  <th ref={bindTableFixedBehavior} key={index}>
                    {renderHeaderMonthNode(index + offset + 1)}
                  </th>
                );
              })}
              <td ref={bindTableFixedBehavior}>
                <IconButton
                  rate={0.4}
                  icon={FaArrowRight}
                  shape="circle"
                  type={offset + totalMonths < data.duracao ? 'dark' : 'disabled'}
                  onClick={onAddOffset}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {paymentContracts.map(paymentContract => (
              <Row
                key={paymentContract.prhId}
                paymentContract={paymentContract}
                maxMonth={totalMonths}
                offset={offset}
                inicioRelativo={data.inicioRelativo}
              />
            ))}
          </tbody>
          <tbody className={styles.fixed}>
            <ResumeRow
              estado="PLA"
              maxMonth={totalMonths}
              payments={payments}
              offset={offset}
            />

            <ResumeRow
              estado="REQ"
              maxMonth={totalMonths}
              payments={payments}
              offset={offset}
            />

            <ResumeRow
              estado="LAT"
              maxMonth={totalMonths}
              payments={payments}
              offset={offset}
            />
            <ResumeRow
              estado="EXE"
              maxMonth={totalMonths}
              payments={payments}
              offset={offset}
            />
          </tbody>
        </table>
      </ScrollPanel>
    </div>
  );
};

type RowProps = {
  paymentContract: PaymentContract;
  maxMonth?: number;
  offset: number;
  inicioRelativo?: Date;
};

const Row: React.FC<RowProps> = ({
  inicioRelativo,
  paymentContract,
  maxMonth,
  offset,
}) => {
  const { nome, pagamentos, cargo } = paymentContract;
  const { tx } = useTranslationX('paymentPanel', 'project');

  const getPaymentByMonth = (mes: number) => {
    return _.find(pagamentos, { mes });
  };

  const renderPaymentNode = (month: number) => {
    const payment = getPaymentByMonth(month);
    if (!payment) {
      return null;
    }
    const relativeMonth = month + offset;
    const paymentDate = moment(inicioRelativo)
      .endOf('day')
      .add(relativeMonth - 1, 'months')
      .utcOffset(DEFAULT_TIMEZONE)
      .toDate();

    return (
      <PaymentNode date={paymentDate} payment={payment} contract={paymentContract} />
    );
  };
  const executedPayments = _.filter(pagamentos, { estado: 'EXE' }).length;

  return (
    <tr>
      <td className={styles.userBadge}>
        <NamedUserBadge name={capitalize(nome)} cargo={capitalize(cargo)} />
      </td>
      <td />
      {Array.from(new Array(maxMonth), (_el, index) => (
        <td
          key={index}
          style={{
            fontSize: '10px',
          }}
        >
          {renderPaymentNode(index + offset + 1)}
        </td>
      ))}
      <td>
        <DonutChart
          data={[
            {
              label: tx('status.ava'),
              color: '#3F75C7',
              value: pagamentos.length - executedPayments,
            },
            {
              label: tx('status.exe'),
              color: '#37A64A',
              value: executedPayments,
            },
          ]}
          legend={{
            show: true,
            fontSize: 10,
            padding: '1px 10px',
          }}
          graph={{
            size: 45,
            lineWidth: 25,
            space: 10,
          }}
        />
      </td>
    </tr>
  );
};

type ResumeRowProps = {
  payments: Payment[];
  estado: PaymentStatus;
  maxMonth?: number;
  offset: number;
};

const ResumeRow: React.FC<ResumeRowProps> = ({ maxMonth, estado, payments, offset }) => {
  const { tx } = useTranslationX('paymentPanel', 'project');
  const getPaymentsByStatus = (mes: number): number | undefined => {
    const paymentsByStatus = _.filter(payments, { mes, estado });

    const havePayments = !!payments.length;

    if (havePayments) {
      return paymentsByStatus.reduce((acc, payment) => acc + payment.valor, 0);
    }

    return undefined;
  };

  return (
    <tr className={styles.resume}>
      <td style={{ textAlign: 'right' }}>{tx(`status.${estado.toLowerCase()}`)}</td>
      <td />
      {Array.from(new Array(maxMonth), (_el, index) => {
        const totalAmount = getPaymentsByStatus(index + offset + 1);
        return (
          <td key={index}>
            {totalAmount
              ? getMasked(totalAmount.toFixed(2), { pattern: 'currency' })
              : undefined}
          </td>
        );
      })}
      <td />
    </tr>
  );
};

export default PaymentTable;
