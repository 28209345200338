import _ from 'lodash';
import { isNil } from 'utils';
import { readAttr, writeAttr } from 'utils/object';
import {
  FormValues,
  Issue,
  FieldData,
  FormObserver,
  FieldMetadata,
  FieldObserverResult,
} from './types';
import { FormObserverOptions, FormObserverResult } from './types';
import { toDate, toSQLTimestamp } from 'utils/calendarUtils';
import { getMasked } from 'utils/inputMask';

type T = { [key: string]: any };

export function formatFromMetadata(
  value: any,
  metadata?: FieldMetadata,
  defaultValue?: string
): string | undefined {
  const mask = readAttr('rules.mask', metadata);
  const options = readAttr('options', metadata);
  if (mask?.pattern === 'currency') {
    return getMasked(Number(value).toFixed(2), mask);
  } else if (mask) {
    return getMasked(Number(value).toFixed(0), mask);
  } else if (options && Array.isArray(options)) {
    return options.find((o: any) => o.value === value)?.text || defaultValue;
  }

  return value !== undefined && value !== null ? value : defaultValue;
}

export function addRejected(rejected?: T, rules?: T) {
  const LEAF_KEYS = ['value', 'key', 'rule'];

  if (!rejected || !rules) {
    return [];
  }

  const rejectedFields: string[] = [];

  const isLeaf = (obj: T) => {
    if (!obj.hasOwnProperty('value')) {
      return false;
    }

    for (const key in obj) {
      if (!LEAF_KEYS.includes(key)) {
        return false;
      }
    }

    return true;
  };

  const recursive = (rejected: T, rules: T, path: string) => {
    for (const key in rejected) {
      const src = rejected[key];
      const dst = rules[key];

      if (typeof src !== 'object' || Array.isArray(src)) {
        continue;
      }

      if (isLeaf(src)) {
        if (typeof dst === 'object') {
          rules[key] = _.merge(dst, {
            rejected: src,
          });
          rejectedFields.push(`${path}${key}`);
        }
      } else {
        recursive(src, dst, `${path}${key}.`);
      }
    }
  };
  recursive(rejected, rules, '');
  return rejectedFields;
}

export function isDiffData(data: Record<string | number, unknown>) {
  if (data.current !== undefined) {
    return true;
  }
  return false;
}

export function evaluate(data: FormValues) {
  const errors: FieldData[] = [];
  const warnings: FieldData[] = [];
  const pending: string[] = [];

  for (const name in data) {
    const field = data[name];

    if (field.disabled) {
      continue;
    }

    if (field.issue) {
      switch (field.issue.type) {
        case 'error':
          errors.push(field);
          break;

        case 'warning':
          warnings.push(field);
          break;

        default:
          pending.push(name);
          break;
      }
    } else if (!field.valid) {
      errors.push(field);
    }

    if (errors.length) {
      break;
    }
  }

  return { errors, warnings, pending };
}

export function getJsonBody(data: FormValues, section?: string) {
  const values = {};

  for (const name in data) {
    const field = data[name];

    if (field.disabled) {
      continue;
    }

    if (!field.omit && (!section || section === field.section)) {
      let newValue = field.value;
      if (field.dataType === 'date') {
        newValue = toDate(field.value);
      } else if (field.dataType === 'datetime') {
        newValue = toSQLTimestamp(field.value, true);
      }
      writeAttr(name, newValue, values, false, true);
    }
  }

  return values;
}

export function getCollection(name: string, data: FormValues) {
  const values = {};

  for (const key in data) {
    if (key.startsWith(`${name}[`)) {
      writeAttr(key, data[key].value, values, false, true);
    }
  }

  return values;
}

export function getFormData(data: FormValues, section?: string) {
  const formData = new FormData();

  for (const name in data) {
    const field = data[name];

    if (field.disabled) {
      continue;
    }

    if (!field.omit && (!section || section === field.section)) {
      if (field.dataType === 'datetime') {
        formData.append(name, stringify(toSQLTimestamp(field.value, true)));
      } else if (field.dataType === 'date') {
        formData.append(name, stringify(toDate(field.value)));
      } else if (field.dataType === 'dateRange') {
        formData.append(`${name}.from`, stringify(toSQLTimestamp(field.value.from)));
        formData.append(`${name}.to`, stringify(toSQLTimestamp(field.value.to)));
      } else if (field.dataType !== 'file') {
        formData.append(name, stringify(field.value));
      } else {
        const array = Array.isArray(field.value) ? field.value : [field.value];

        for (const item of array) {
          if (item instanceof File) {
            formData.append(name, item, item.name);
          }
        }
      }
    }
  }

  return formData;
}

export function stringify(value: any) {
  return isNil(value) ? '' : String(value);
}

export function getObserverInitialValue(
  formValues: FormValues,
  options: FormObserverOptions,
  initialValues: { [key: string]: any }
): FormObserverResult {
  if (typeof options === 'string') {
    return { name: options, data: { value: readAttr(options, initialValues) } };
  }

  if (options.hasOwnProperty('field')) {
    const name = (options as any).field;
    return { name, data: { value: readAttr(name, initialValues) } };
  }

  if (options.hasOwnProperty('section')) {
    const name = (options as any).section;
    const data: FieldData[] = [];

    let valid = true;

    for (const key in formValues) {
      const field = formValues[key];

      if (field.disabled) {
        continue;
      }

      if (field.section === name) {
        data.push(field);

        if (!field.valid) {
          valid = false;
        }
      }
    }

    return { name, data, valid: data.length ? valid : undefined };
  }

  let valid = true;

  for (const key in formValues) {
    if (!formValues[key].valid) {
      valid = false;
      break;
    }
  }

  return { name: 'valid', value: valid };
}

export function notifyObserver(
  observer: FormObserver,
  db: FormValues,
  name: string,
  oldData?: FieldData,
  newData?: FieldData
) {
  const { options, callback } = observer;

  if (!options) {
    return callback({ name, data: db });
  }

  const parseMatch = (name: string, props: ('value' | 'valid')[]) => {
    for (const prop of props) {
      if (hasChanged(prop, oldData, newData)) {
        callback({ name, data: newData });
        break;
      }
    }
  };

  if (typeof options === 'string') {
    if (options === name) {
      parseMatch(name, ['value', 'valid']);
    }
  } else if (options instanceof RegExp) {
    if (options.test(name)) {
      parseMatch(name, ['value', 'valid']);
    }
  } else if (options.hasOwnProperty('onSave')) {
    if (name === 'onSave') {
      callback({ name });
    }
  } else if (options.hasOwnProperty('field')) {
    if ((options as any).field === name) {
      parseMatch(name, options.prop ? [options.prop] : ['value', 'valid']);
    }
  } else if (options.hasOwnProperty('section')) {
    const sectionName = oldData?.section || newData?.section;

    if (!sectionName || sectionName !== (options as any).section) {
      return;
    }

    const data: FieldData[] = [];
    let validOmitField = true;

    for (const key in db) {
      const field = db[key];

      if (field.disabled) {
        continue;
      }

      if (field.section === sectionName && key !== name) {
        data.push(field);

        if (!field.valid) {
          validOmitField = false;
        }
      }
    }

    const oldValid = sectionValid(data.length, validOmitField, oldData);
    const newValid = sectionValid(data.length, validOmitField, newData);

    if (newData) {
      data.push(newData);
    }

    if (options.prop) {
      if (options.prop === 'valid') {
        if (oldValid !== newValid) {
          callback({ name: sectionName, data, valid: newValid });
        }
      } else if (hasChanged('value', oldData, newData)) {
        callback({ name: sectionName, data, valid: newValid });
      }
    } else if (hasChanged('value', oldData, newData) || oldValid !== newValid) {
      callback({ name: sectionName, data, valid: newValid });
    }
  } else if (options.prop === 'valid') {
    let validOmitField = true;
    let length = 0;

    for (const key in db) {
      if (key !== name && !db[key].valid) {
        validOmitField = false;
      }

      ++length;
    }

    const oldValid = sectionValid(length, validOmitField, oldData);
    const newValid = sectionValid(length, validOmitField, newData);

    if (oldValid !== newValid) {
      callback({ name: 'valid', value: newValid });
    }
  }
}

export function isValidField(issue?: Issue): boolean | undefined {
  if (issue) {
    if (issue.type === 'pending') {
      return undefined;
    }

    if (issue.type === 'error') {
      return false;
    }
  }

  return true;
}

export function removeField(name: string, data: { [key: string]: any }) {
  const openListIndex = name.lastIndexOf('[');
  const changes: { [key: string]: { oldData: any; newData?: any } } = {};

  const remove = (key: string) => {
    changes[key] = { oldData: data[key] };
    delete data[key];
    return changes;
  };

  if (openListIndex < 0) {
    // eg. name
    return remove(name);
  }

  const closeListIndex = name.indexOf(']', openListIndex);

  if (closeListIndex < name.length - 1) {
    // eg. name[idx].prop
    return remove(name);
  }

  // eg. name[idx]
  // reindexing required

  const p = split(name, openListIndex + 1, closeListIndex);
  const siblings: [string, number, string, string][] = [];
  const oldData: { [key: string]: any } = {};

  for (const key in data) {
    if (key.startsWith(p[0])) {
      oldData[key] = data[key];

      if (key.startsWith(name)) {
        delete data[key];
      } else {
        siblings.push(split(key, openListIndex + 1, key.indexOf(']', openListIndex)));
      }
    }
  }

  siblings.sort((a, b) => a[1] - b[1]);

  const targetIndex = p[1];

  for (const item of siblings) {
    const index = item[1];

    if (index > targetIndex) {
      data[`${item[0]}${index - 1}${item[2]}`] = data[item[3]];
      delete data[item[3]];
    }
  }

  for (const key in oldData) {
    if (oldData[key] !== data[key]) {
      changes[key] = { oldData: oldData[key], newData: data[key] };
    }
  }

  return changes;
}

export function removeIndices(fieldName: string) {
  return fieldName.replace(/\[([^[]*)\]/g, '');
}

export function insertIndex(name: string, data: { [key: string]: any }, index: number) {
  const openListIndex = name.length;
  const siblings: [string, number, string, string][] = [];

  for (const key in data) {
    if (key.startsWith(name)) {
      const closeListIndex = key.indexOf(']', openListIndex);

      if (closeListIndex > openListIndex) {
        const p = split(key, openListIndex + 1, closeListIndex);

        if (p[1] >= index) {
          siblings.push(p);
        }
      }
    }
  }

  siblings.sort((a, b) => b[1] - a[1]);

  for (const item of siblings) {
    delete Object.assign(data, { [`${item[0]}${item[1] + 1}${item[2]}`]: data[item[3]] })[
      item[3]
    ];
  }
}

export function getCollectionName(fieldName: string, data: { [key: string]: any }) {
  for (const key in data) {
    if (
      key.startsWith(fieldName) &&
      (fieldName.endsWith(']') || key.charAt(fieldName.length) === '[')
    ) {
      return fieldName.endsWith(']')
        ? fieldName.substring(0, fieldName.lastIndexOf('['))
        : fieldName;
    }
  }
}

function hasChanged(prop: 'value' | 'valid', oldData?: FieldData, newData?: FieldData) {
  if (oldData && newData) {
    return oldData[prop] !== newData[prop];
  }

  if (oldData || newData) {
    if (oldData) {
      return !isNil(oldData[prop]);
    }

    if (newData) {
      return !isNil(newData[prop]);
    }
  }
}

function sectionValid(length: number, validOmit: boolean, data?: FieldData) {
  if (length) {
    return data ? validOmit && data.valid : validOmit;
  }

  return data ? data.valid : undefined;
}

/**
 * 'abc[x].h' -> ['abc[', x, '].h', 'abc[x].h']
 */
function split(
  name: string,
  idx1: number,
  idx2: number
): [string, number, string, string] {
  return [
    name.substring(0, idx1),
    parseInt(name.substring(idx1, idx2)),
    name.substring(idx2),
    name,
  ];
}

export function getDataFromObserver<T>(result?: FieldObserverResult, fallback?: T) {
  if (result) {
    return (result.data?.value as T) || fallback;
  }
  return fallback;
}

export function currencyDefault() {
  return {
    metadata: {
      rules: {
        min: '0.00',
        minlength: 1,
        maxlength: 14,
        mask: {
          pattern: 'currency',
        },
      },
    },
  };
}
